<script setup>
import { computed } from '#imports';

const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    validator: (value) =>
      ['small', 'medium', 'big', 'large', 'huge'].includes(value),
    default: 'small',
  },
});

const classes = computed(() => ({
  'base-avatar--medium': props.size === 'medium',
  'base-avatar--big': props.size === 'big',
  'base-avatar--huge': props.size === 'huge',
  'base-avatar--large': props.size === 'large',
}));
const assetId = computed(() => props.image.asset._ref);
const caption = computed(() => props.name || `Изображение-${assetId.value}`);
const sizes = computed(() => {
  switch (props.size) {
    case 'huge':
      return { width: 128, height: 128 };
    case 'large':
      return { width: 90, height: 90 };
    case 'big':
      return { width: 64, height: 64 };
    case 'medium':
      return { width: 36, height: 36 };
    default:
      return { width: 28, height: 28 };
  }
});
</script>

<template>
  <SanityImage
    class="base-avatar"
    :class="classes"
    :asset-id="assetId"
    :w="sizes.width"
    :h="sizes.height"
    :alt="caption"
    fit="crop"
    dpr="3"
    auto="format"
  />
</template>

<style lang="postcss" scoped>
.base-avatar {
  display: block;
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;

  &--huge {
    width: 128px;
    height: 128px;
  }

  &--big {
    width: 64px;
    height: 64px;
  }

  &--medium {
    width: 36px;
    height: 36px;
  }

  &--large {
    width: 90px;
    height: 90px;
  }

  @media (--small-vp) {
    &--huge {
      width: 100px;
      height: 100px;
    }

    &--big {
      width: 56px;
      height: 56px;
    }

    &--medium {
      width: 28px;
      height: 28px;
    }
  }
}
</style>
