import { useNuxtApp } from '#imports';

export function useImageSource({
  image,
  dpr = 1,
  width,
  height,
  quality = 75,
  fit,
  crop = 'center',
}) {
  const { $urlFor } = useNuxtApp();

  if (!image) return '';
  if (!fit)
    return $urlFor(image)
      .quality(quality)
      .width(width)
      .height(height)
      .dpr(dpr)
      .url();

  return $urlFor(image)
    .quality(quality)
    .width(width)
    .height(height)
    .dpr(dpr)
    .fit(fit)
    .crop(crop)
    .url();
}
